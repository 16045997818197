import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import Person from "../assets/storefrontGrey.svg";
import editImg from "../assets/edit_square.png";
import DropDwn from "../assets/drop-dwn.png";
import {
  AddStaffPageText,
  create_restaurants,
} from "./../CommonJquery/WebsiteText";
import {
  handleEmailChange,
  handleNumbersChange,
  handleAphabetsChange,
  handleError,
  handlePasswordChange,
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  empty_form,
  handleIaphabetnumberChange,
  cencelChanges,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_cuinise_super,
  get_country_data,
  get_all_admin_cuinise,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";
function CuisinesAdd() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [designations, setdesignations] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [country, setCountry] = useState([]);
  const [countryget, setCountryget] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [temporaryAddress, setTemporaryAddress] = useState("");
  const [copyAddress, setCopyAddress] = useState(false);

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      // if (file.size < 20 * 1024) {
      //   // 200KB in bytes
      //   alert("File size is below the minimum limit (20KB).");
      //   return;
      // }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        alert("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };
  const handleChange1 = (event) => {
    console.log(event);
    setCountryget(event);
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("old_image_link", editOldImageData);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/cuisines_view");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_admin_cuinise, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_blog.length > 0) {
            seteditStaffData(Response.data.message.data_blog[0]);
            setEditorDatMainID(Response.data.message.data_blog[0].primary_id);
            seteditOldImageData(Response.data.message.data_blog[0].image_name);
            setCountryget(Response.data.message.data_blog[0].country_id +'~'+ Response.data.message.data_blog[0].country);
            setDynaicimage({
              event_list_image_show:
                APL_LINK +
                Response.data.message.data_restaurant_image +
                Response.data.message.data_blog[0].image_name,
            });
          }
          setdesignations(Response.data.message.data_designation);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get_country();
  }, []);
  console.log(countryget)
  const master_data_get_country = async () => {
    const fd = new FormData();
    setshowLoaderAdmin(true);
    await server_post_data(get_country_data, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message.country);
          setCountry(Response.data.message.country);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        // handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const handleCheckboxChange2 = () => {
    setCopyAddress(!copyAddress); // Toggle checkbox state
    if (!copyAddress) {
      setTemporaryAddress(permanentAddress); // Copy permanent address to temporary address
    } else {
      setTemporaryAddress(""); // Clear temporary address
    }
  };
  console.log(editStaffData)
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                {location.pathname.includes("cuisines_edit") ? (
                  <p>Edit Cuisine</p>
                ) : (
                  <p>Add Cuisine</p>
                )}
                <img src={GreyArrow} alt="Dfoodo Dashboard" />
              </div>
            </div>

            <div className="page_body">
              <div className="addStaffForms">
                <form id="addNewStaff">
                  <div className="row m-0 justify_center">
                    <div className="col-xl-6 col-lg-7">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0">
                            <div className="col-lg-3 col-md-4 mx-auto">
                              <div
                                className={
                                  "inpContainer imginputContainer flex-column "
                                }
                              >
                                <div
                                  className={
                                    dynaicimage ? "" : " inputDiv1 imgInput"
                                  }
                                >
                                  <label
                                    htmlFor="event_list_image"
                                    className="imgInptImg"
                                  >
                                    {dynaicimage &&
                                    dynaicimage.event_list_image_show ? (
                                      <div
                                        className="imgDiv"
                                        htmlFor="event_list_image"
                                      >
                                        <img
                                          src={
                                            dynaicimage.event_list_image_show
                                          }
                                          onError={(e) =>
                                            (e.target.src = Person)
                                          }
                                          alt="Preview"
                                        />
                                        <img
                                          className="profileImgEdit"
                                          src={editImg}
                                          alt="Dfoodo Dashboard"
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        <img
                                          style={{ width: "1.5rem" }}
                                          src={Person}
                                          alt="Dfoodo Dashboard"
                                        />
                                        <p className="m-0">
                                          {AddStaffPageText.Add_Image}
                                        </p>
                                        <div className="imgValidationalert ">
                                          {/* <p>
                                            {AddStaffPageText.Image_Validation}
                                          </p> */}
                                          <p>
                                            {AddStaffPageText.Image_Validation2}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </label>
                                </div>
                                <input
                                  name="event_list_image"
                                  id="event_list_image"
                                  type="file"
                                  onChange={handleFileChangedynamic(
                                    "event_list_image"
                                  )}
                                  className={`form-control ${
                                    dynaicimage &&
                                    dynaicimage.event_list_image_show
                                      ? ""
                                      : "trio_mandatory"
                                  }`}
                                  accept=".jpg,.jpeg,.png"
                                  hidden
                                />
                              <span className="condition_error"></span>
                              </div>
                            </div>
                            <div className="col-md-8 mt-3">
                              <div className="addstaffInputs">
                                <div className="inpContainer">
                                  <div className="resturant_icon image_icon_position image_icon_position1">
                                    <input
                                      type="text"
                                      id="restaurant_name"
                                      name="restaurant_name"
                                      tabIndex="1"
                                      // placeholder={
                                      //   create_restaurants.Restaurant_name
                                      // }
                                      placeholder="Cuisine Name"
                                      minLength={3}
                                      maxLength={75}
                                      className="trio_name trio_mandatory form-control  input_field_custom1 "
                                      onInput={handleAphabetsChange}
                                      defaultValue={
                                        editStaffData.cuisine_name || ""
                                      }
                                    />
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                                <div className="person__business_center_image image_icon_position1 mt-1">
                                  <div className="dropDownn">
                                    <img src={DropDwn} alt="img"></img>
                                  </div>
                                  <select
                                    id="select_designation"
                                    name="admin_country"
                                    className="trio_mandatory form-control input_field_custom1"
                                    onChange={(e) =>
                                      handleChange1(e.target.value)
                                    }
                                  >
                                    <option value="" disabled hidden>
                                      Select Country
                                    </option>
                                    {country &&
                                      country.map((optionsss, index) => (
                                        <option key={index}
                                          value={`${optionsss.primary_id}~${optionsss.country}`}
                                          selected={
                                            editStaffData.country_id ===
                                            optionsss.primary_id
                                          }
                                        >
                                          {optionsss.country}
                                        </option>
                                      ))}
                                  </select>
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div className="saveFormBtns row m-0 p-0 hidden">
                            <button
                              className="btnCancel"
                              type="button"
                              onClick={cencelChanges}
                            >
                              {AddStaffPageText.cencel_text}
                            </button>

                            <button
                              className="btnSave"
                              type="button"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "addNewStaff",
                                  save_update_cuinise_super
                                )
                              }
                            >
                              {location.pathname.includes("/edit_restaurants")
                                ? AddStaffPageText.update_text
                                : AddStaffPageText.save_text}
                            </button>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="saveFormBtns">
                          <button
                            className="btnCancel"
                            type="button"
                            onClick={cencelChanges}
                          >
                            {AddStaffPageText.cencel_text}
                          </button>

                          <button
                            className="btnSave"
                            type="button"
                            onClick={() =>
                              handleSaveChangesdynamic(
                                "addNewStaff",
                                save_update_cuinise_super
                              )
                            }
                          >
                            {location.pathname.includes("/edit_staff")
                              ? AddStaffPageText.update_text
                              : AddStaffPageText.save_text}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CuisinesAdd;
