import React, { useState } from "react";
import Header from "./Header";
import Faqs from "./Faqs";
import UserManual from "./UserManual";
import { Link } from "react-router-dom";
import Help from "./Help";
function Manuals() {
  const [calendarTab, setCalendarTab] = useState(false);
  const [historyTab, setHistoryTab] = useState(false);
  const [support, setSupport] = useState(true);

  const toggleTabs = (Tab) => {
    // if (Tab === "Calender") {
    //   window.location.reload();
    // }
    setCalendarTab(Tab === "Calender");
    setHistoryTab(Tab === "History");
    setSupport(Tab === "Support");
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head mb-3">
              <div className="calendertabs">
                <div className="calendertabs_container">
                  <div
                    className={`calendertabsBtn width150px ${
                      support ? "activeCalendarTab" : ""
                    }`}
                    onClick={() => toggleTabs("Support")}
                  >
                    <p>Support</p>
                  </div>
                  <div
                    className={`calendertabsBtn width150px ${
                      calendarTab ? "activeCalendarTab" : ""
                    }`}
                    onClick={() => toggleTabs("Calender")}
                  >
                    <p>FAQs</p>
                  </div>
                  <div
                    className={`calendertabsBtn width150px ${
                      historyTab ? "activeCalendarTab" : ""
                    }`}
                    onClick={() => toggleTabs("History")}
                  >
                    <p>Knowledge Base</p>
                  </div>
                </div>
              </div>
              <div className="tabsBtn">
                {/* {support && (
                  <Link to="/Add_Faq">
                    <button
                      type="button"
                      className="btnAddStaff darkBg add_FAQ"
                    >
                     Support
                    </button>
                  </Link>
                )} */}
                {calendarTab && (
                  <Link to="/Add_Faq">
                    <button
                      type="button"
                      className="btnAddStaff darkBg add_FAQ"
                    >
                      Add FAQ
                    </button>
                  </Link>
                )}
                {historyTab && (
                  <Link to="/Add_KB">
                    <button
                      type="button"
                      className="btnAddStaff darkBg add_User_Manual"
                    >
                      Add KB
                    </button>
                  </Link>
                )}
              </div>
            </div>

            <div className="page_body">
              {calendarTab && <Faqs />}
              {historyTab && <UserManual toggleTabs={toggleTabs}  />}
              {support && <Help />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Manuals;
