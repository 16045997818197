import React, { useState,useEffect } from "react";
import Header from "./Header";
import ReservationReport from "./ReservationReport";
import SalesReport from "./SalesReport";
import GuestReport from "./GuestReports";
import Select from "react-select";
import {
  server_post_data,
  get_all_analyticsReservation,
  get_analyticsReservationHourly,
  get_all_restaurant_list,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleCallClick,
  computeplussevendays,
} from "../CommonJquery/CommonJquery";
import html2canvas from "html2canvas";
import {
  options_select_drop_feedback,
  Analytics_text,
} from "./../CommonJquery/WebsiteText";
import { storeData } from "../LocalConnection/LocalConnection.js";
import SalesRpt from "./SalesRpt.js";
import ReservationRpt from "./ReservationRpt.js";

let default_restaurant_id = "0";
function Report() {
  const [reservation, setreservation] = useState(true);
  const [sales, setsales] = useState(false);
  const [guest, setguest] = useState(false);
  const [salesr, setsalesr] = useState(false);
  const [reserv, setreserv] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [RestaurantName, setRestaurantName] = useState("");
  const [restuarnt, setrestuarnt] = useState([]);


  const toggleTabs = (Tab) => {
    setreservation(Tab === "Reservation");
    setsales(Tab === "Sales");
    setguest(Tab === "Guest");
    setsalesr(Tab === "Guest");
    setsalesr(Tab === "Salesr");
    setreserv(Tab === "Reserv");
  };



  const master_data_get = async (flag) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", flag);
    await server_post_data(get_all_restaurant_list, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setrestuarnt(Response.data.message.reservation_list);
          default_restaurant_id =
            Response.data.message.reservation_list[0].primary_id;
          setRestaurantName(
            Response.data.message.reservation_list[0].restaurant_name +
              "(" +
              Response.data.message.reservation_list[0].restaurant_city +
              ")"
          );
          // master_data_get_reservation("", "", 1, "", selected.value);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get("0");
  }, []);

  const handleChange1 = (selectedOption) => {
    console.log(selectedOption);
    default_restaurant_id = selectedOption.value;
    setRestaurantName(selectedOption.label);
    console.log(default_restaurant_id)
    // master_data_get_reservation("", "", 1, "", selected.value);
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head" style={{paddingTop:'1.6rem'}}>

              

              <div className="calendertabs mt-2 ">
                <div
                  className="calendertabs_container gap-0"
                  style={{ border: "none" }}
                >
                  <div
                    className={`reportTabs ${reservation ? "selectedReports" : ""
                      }`}
                    onClick={() => toggleTabs("Reservation")}
                  >
                    <p>Restaurants Report</p>
                  </div>
                  <div
                    className={`reportTabs ${sales ? "selectedReports" : ""}`}
                    onClick={() => toggleTabs("Sales")}
                  >
                    <p>Subscription Report</p>
                  </div>
                  <div
                    className={`reportTabs ${guest ? "selectedReports" : ""}`}
                    onClick={() => toggleTabs("Guest")}
                  >
                    <p>Guest Report</p>
                  </div>
                  <div
                    className={`reportTabs ${salesr ? "selectedReports" : ""}`}
                    onClick={() => toggleTabs("Salesr")}
                  >
                    <p>Sales Report</p>
                  </div>
                  <div
                    className={`reportTabs ${reserv ? "selectedReports" : ""}`}
                    onClick={() => toggleTabs("Reserv")}
                  >
                    <p>Reservation Report</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="page_body px-2 py-1">
              {reservation && <SalesReport />}
              {sales && <ReservationReport />}
              {guest && <GuestReport />}
              {salesr && <SalesRpt />}
              {reserv && <ReservationRpt />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report;
