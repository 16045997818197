import React, { useEffect, useState, useRef } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import dropdown from "../assets/arrow_drop_down_24px.svg";
import InfoIcon from "../assets/(i).png";
import DownloadImg from "../assets/print.svg";
import Downgrade from "../assets/downgrade.svg";
import Upgrade from "../assets/upgrade.svg";
import Chart from "react-apexcharts";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  server_post_data,
  get_all_dashboard_data,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleCallClick,
  computeplussevendays,
  formatDateStringdot,
} from "../CommonJquery/CommonJquery.js";
import html2canvas from "html2canvas";
import {
  options_select_drop_feedback,
  Analytics_text,
} from "../CommonJquery/WebsiteText.js";

function Dashboard() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [customDateActive, setCustomDateActive] = useState(false);
  const [CalenderStartDate, setCalenderStartDate] = useState();
  const [CalenderEndDate, setCalenderEndDate] = useState();
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(options_select_drop_feedback[0]);
  const [CardData, setCardData] = useState([]);
  const [series, setseries] = useState([0, 0, 0]);
  const [filteredData, setfilteredData] = useState([]);
  const [isFilterActive, setIsFIlterActive] = useState(false);
  const [selectedFilter, setIsSelectedFilter] = useState("Online");
  const [isChanelActive, setIsChanelActive] = useState(false);
  const [selectedChanel, setIsSelectedChanel] = useState("Online");
  const [selectedGraph1, setSelectedGraph1] = useState(true);
  const [showWeekdays, setShowWeekdays] = useState(false);
  const [selectedGraphMon, setSelectedGraphMon] = useState(false);
  const [selectedGraphTue, setSelectedGraphTue] = useState(false);
  const [selectedGraphWed, setSelectedGraphWed] = useState(false);
  const [selectedGraphThu, setSelectedGraphThu] = useState(false);
  const [selectedGraphFri, setSelectedGraphFri] = useState(false);
  const [selectedGraphSat, setSelectedGraphSat] = useState(false);
  const [selectedGraphSun, setSelectedGraphSun] = useState(false);
  const [xAxisCategories, setXAxisCategories] = useState([]);
  const [GraphStatics, setGraphStatics] = useState([]);
  const [Statics, setStatics] = useState([]);
  const [BookingBarData, setBookingBarData] = useState([]);
  const [RevenueBarData, setRevenueBarData] = useState([]);
  const [BookingBarTimeData, setBookingBarTimeData] = useState([]);
  const [RevenueBarTimeData, setRevenueBarTimeData] = useState([]);
  const [xAxisTime, setxAxisTime] = useState([]);
  const [TotalComplete, setTotalComplete] = useState(0);
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());

  const toggleTabs = (Tab) => {
    setSelectedGraph1(Tab === "Day");
    setShowWeekdays(
      Tab === "Mon" ||
      Tab === "Tue" ||
      Tab === "Wed" ||
      Tab === "Thu" ||
      Tab === "Fri" ||
      Tab === "Sat" ||
      Tab === "Sun"
    );
    setSelectedGraphMon(Tab === "Mon");
    setSelectedGraphTue(Tab === "Tue");
    setSelectedGraphWed(Tab === "Wed");
    setSelectedGraphThu(Tab === "Thu");
    setSelectedGraphFri(Tab === "Fri");
    setSelectedGraphSat(Tab === "Sat");
    setSelectedGraphSun(Tab === "Sun");
  };
  const handleDownloadButtonClick = () => {
    html2canvas(document.body).then((canvas) => {
      const link = document.createElement("a");
      link.download = "Analytics.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  // code by shubham jain for custom date functionality
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get("", "", flag, call_id, selected.value);
  }, []);

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    select_type
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    const current_date = new Date(); // Initialize current_date with the current date
    if (select_type === "today") {
      start_date = start_date_fn(current_date);
      end_date = end_date_fn(current_date);
    } else if (select_type === "last_7_days") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      start_date = start_date_fn(sevenDaysAgo);
      end_date = end_date_fn(current_date);
    } else if (select_type === "this_month") {
      const firstDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        0
      );
      start_date = start_date_fn(firstDayOfMonth);
      end_date = end_date_fn(lastDayOfMonth);
    } else if (select_type === "last_month") {
      const firstDayOfLastMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() - 1,
        1
      );
      const lastDayOfLastMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        0
      );
      start_date = start_date_fn(firstDayOfLastMonth);
      end_date = end_date_fn(lastDayOfLastMonth);
    } else if (select_type === "this_year") {
      const firstDayOfYear = new Date(current_date.getFullYear(), 0, 1);
      const lastDayOfYear = new Date(current_date.getFullYear(), 11, 31);
      start_date = start_date_fn(firstDayOfYear);
      end_date = end_date_fn(current_date);
    }

    function start_date_fn(start_date) {
      // Formatting start date
      const start_year = start_date.getFullYear();
      const start_month = (start_date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const start_day = start_date.getDate().toString().padStart(2, "0");
      return `${start_year}-${start_month}-${start_day}`;
    }

    function end_date_fn(end_date) {
      // Formatting end date
      const end_year = end_date.getFullYear();
      const end_month = (end_date.getMonth() + 1).toString().padStart(2, "0");
      const end_day = end_date.getDate().toString().padStart(2, "0");
      return `${end_year}-${end_month}-${end_day}`;
    }
    setCalenderStartDate(start_date);
    setCalenderEndDate(end_date);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("select_type", select_type);
    // gethourdata(start_date, start_date, end_date);
    setShowWeekdays(true);
    await server_post_data(get_all_dashboard_data, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setCardData(Response.data.message);
          setfilteredData(Response.data.message.reservation_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const search_data = () => {
    master_data_get(startDate, endDate, "1", "", selected.value);
  };

  const select_dropdown = (itemsdata) => {
    setIsSelected(itemsdata);
    setIsActive(!isActive);

    if (itemsdata.datepicker_show) {
      setCustomDateActive(true);
    } else {
      setCustomDateActive(false);
      master_data_get("", "", "1", "", itemsdata.value);
    }
  };
  console.log(CardData)
  const calculateBusinessGrowth = (currentMonthBookings, lastMonthBookings) => {
    let businessGrowth = 0;
    if (Number(lastMonthBookings) > 0) {
      businessGrowth = (
        ((Number(currentMonthBookings) - Number(lastMonthBookings)) /
          Number(lastMonthBookings)) *
        100
      ).toFixed(2);
    }

    if (isNaN(businessGrowth)) {
      return 0;
    } else {
      return businessGrowth;
    }
  };

  // function formatDate(bookDate) {
  //   // Assuming item.book_date is in the format "yyyy-mm-dd"
  //   let dateParts = bookDate.split("-");
  //   let formattedDate = dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
  //   return formattedDate;
  // }

  // code by shubham jain for custom date functionality

  const tableRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPageCount = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          // onClick={() => paginate(number)}
          onClick={() => handlePageClick(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="analyticsHead">
                <div className="analyticFIlter">
                  <div className="col-xl-10">
                    <div className="row m-0">
                      <div className="col-md-4">
                        <div className="dropdownCustom" ref={dropdownRef}>
                          <div
                            onClick={(e) => {
                              setIsActive(!isActive);
                            }}
                            className="dropdownCustom-btn"
                          >
                            {Analytics_text.Period_text}: {selected.label}
                            <span
                              className={
                                isActive
                                  ? "fas fa-caret-up"
                                  : "fas fa-caret-down"
                              }
                            >
                              <img src={dropdown} alt="Dfoodo Dashboard" />
                            </span>
                          </div>
                          <div
                            className="dropdownCustom-content"
                            style={{ display: isActive ? "block" : "none" }}
                          >
                            {options_select_drop_feedback.map(function (
                              items,
                              index
                            ) {
                              return (
                                <div
                                  onClick={(e) => {
                                    select_dropdown(items);
                                  }}
                                  className="itemDrop"
                                  key={index}
                                >
                                  {items.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {customDateActive && (
                        <>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="startDate"
                                type="date"
                                placeholder="From Date"
                                defaultValue={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="endDate"
                                type="date"
                                placeholder="To Date"
                                defaultValue={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="downloadBtnAna">
                              <button onClick={() => search_data()}>
                                {Analytics_text.Search_text}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="downloadBtnAna">
                  <button onClick={handleDownloadButtonClick}>
                    {Analytics_text.download_text}
                  </button>
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="analytics">
                <div className="analytics_container">
                  <div className="row m-0">
                    <div className="col-md-12">
                      <div className="analyticsCardsContainer">
                        <div className="analyticsCardsRow">
                          <div className="row m-0">
                            <div className="col-md-3 paddingLeft1200">
                              <Link to='/View_Restaurants'>
                                <div className="analyticsCard colorCard1">
                                  <p>Total Restaurants</p>
                                  <h5>{CardData.total_Restaurant}</h5>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-3 paddingLeft1200">
                              <Link to='/View_Guest'>
                              <div className="analyticsCard colorCard2">
                                <p>Total Guest </p>
                                {/* <p>{Analytics_text.arrival_txt}</p> */}
                                <h5>{CardData.owner_listsss}</h5>
                              </div>
                              </Link>
                            </div>
                            {/* <div className="col-md-3 paddingLeft1200">
                              <div className="analyticsCard colorCard3">
                                <p>Occupancy</p>
                                <h5>{CardData.total_occupancy}%</h5>
                              </div>
                            </div> */}
                            <div className="col-md-3 paddingLeft1200">
                              <Link to='/View_Reservation'>
                              <div className="analyticsCard colorCard3">
                                <p>Total Bookings</p>
                                {/* <p>{Analytics_text.complete_txt}</p> */}
                                <h5>{CardData.total_spacial_booking}</h5>
                              </div>
                              </Link>
                            </div>
                            <div className="col-md-3 paddingLeft1200">
                              <div className="analyticsCard colorCard4">
                                {/* <p>{Analytics_text.Approval_Pending}</p> */}
                                <p>Total Revenue</p>

                                <h5>{CardData.total_revenue_generate}</h5>
                                {/* <h5>{Statics.totalApprovalPending}</h5> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="feedBackTable">
                    <div className="feedBackTable_container">
                      <h5>Restaurant</h5>
                      <div className="tableResponsive">
                        <div className="tableResponsive_container">
                          <table
                            id="myTable"
                            ref={tableRef}
                            className="display table"
                          >
                            <thead>
                              <tr>
                                <th scope="col" className="th3">
                                  S.No.
                                </th>
                                <th scope="col">Entry Date</th>
                                <th scope="col">Owner Name</th>
                                {/* <th scope="col">Staff Name</th> */}
                                <th scope="col">Restaurant Name</th>
                                <th scope="col">City</th>
                                <th className="th4">Country</th>
                              </tr>
                              <tr style={{ height: "25px" }}></tr>
                            </thead>
                            <tbody>
                              {currentItems &&
                                currentItems.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>
                                        <div className="recentANme">
                                          <p>
                                            {(currentPage - 1) * itemsPerPage +
                                              index +
                                              1}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="recentContact">
                                          <p>
                                            {formatDateStringdot(
                                              item.entry_date
                                            )}
                                          </p>
                                        </div>
                                      </td>
                                      {/* <td>
                                        <div className="recentContact">
                                          <p>
                                            {item.owner_list &&
                                              item.owner_list[0]
                                              ? item.owner_list[0].Owner_fname
                                              : ""}{" "}
                                            {item.owner_list &&
                                              item.owner_list[0]
                                              ? item.owner_list[0].Owner_lname
                                              : ""}
                                          </p>
                                        </div>
                                      </td> */}
                                      <td>
                                        <div className="recentContact">
                                          <p>
                                            {item.staff_list &&
                                              item.staff_list[0]
                                              ? item.staff_list[0].admin_name
                                              : ""}{" "}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="recentContact">
                                          <p>{item.restaurant_name}</p>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="recentContact">
                                          <p>
                                            <p>{item.restaurant_city}</p>
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="recentContact">
                                          <p>
                                            <p>{item.restaurant_country}</p>
                                          </p>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr
                                      style={{
                                        height: "1rem",
                                        boxShadow: "none",
                                      }}
                                    ></tr>
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <Pagination>
                        <div className="paginationContainer">
                          <div className="nxtBttnTble">
                            {!currentItems && currentPage !== 1 ? (
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    prev > 1 ? prev - 1 : prev
                                  )
                                }
                              >
                                {Analytics_text.Previous_text}
                              </button>
                            ) : null}
                          </div>
                          <div className="d-flex gap-2">
                            {renderPaginationItems()}
                          </div>
                          {!currentItems && (
                            <div className="nxtBttnTble">
                              <button
                                className="btn btn-primary"
                                disabled={currentPage === totalPageCount}
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    prev < totalPageCount ? prev + 1 : prev
                                  )
                                }
                              >
                                {Analytics_text.next_text}
                              </button>
                            </div>
                          )}
                        </div>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
