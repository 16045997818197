import React, { useState, useEffect, useRef } from "react";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import Person from "../assets/person.png";
import PersonCount from "../assets/account_circle.svg";
import Pets from "../assets/pets.svg";
import Childs from "../assets/escalator_warning.svg";
import { Modal, Button } from "react-bootstrap";
import DoneGreen from "../assets/doneGreen.svg";
import Loader from "./Loader.js";
import userIcn from "../assets/person_pin_circle.svg";
import OrngMsg from "../assets/orngMsg.svg";
import Header from "./Header";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  ViewStaffPagesText,
  options_search,
  GuestManagementPageText,
} from "./../CommonJquery/WebsiteText";
import TimeTable from "../assets/time_table.svg";
import {
  server_post_data,
  view_reservation_history,
  update_action_reservation,
  APL_LINK,
  get_all_notes_by_id,
} from "../ServiceConnection/serviceconnection.js";
import {
  computeTodayDate,
  handleAphabetsChange,
  handleError,
  inputdateformateChange,
  formatTimeintotwodigit,
  handleLinkClick,
  handleEmailChange,
  handleNumbersChange,
  handleIaphabetnumberChange,
  formatDateStringdot,
  formatTimeFormatcustom,
  computeplussevendays,
} from "../CommonJquery/CommonJquery.js";
const ReservationHistory = () => {
  let flag = "1";
  let select_option_str = "";
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [SelectedData, setSelectedData] = useState([]);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [noteShow2, setNoteShow2] = useState(false);
  const [flagData, setflagData] = useState("1");
  let formattedSevenDaysAgo = computeplussevendays();
  let formattedToday = computeTodayDate();

  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(formattedSevenDaysAgo);
  const [endDate, setEndDate] = useState(formattedToday);
  const [modalShow, setModalShow] = useState(false);
  const [Moneyiconleft, setMoneyiconleft] = useState(null);
  const [Moneyiconright, setMoneyiconright] = useState(null);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [SelectedDataDetails, setSelectedDataDetails] = useState([]);
  const searchData = useRef(null);
  const handleClose = () => setModalShow(false);
  const handleNoteClose2 = () => {
    setNoteShow2(false);
  };
  const handleShow = (data_call, click_type, index) => {
    console.log(click_type);
    select_option_str = click_type;
    if (click_type === "accepts") {
      setSelectedData({
        primary_id: data_call.primary_id,
        restaurant_id: data_call.restaurant_id,
        restaurant_name:
          data_call.restaurant_list && data_call.restaurant_list.length > 0
            ? data_call.restaurant_list[0].restaurant_name +
              "(" +
              data_call.restaurant_list[0].restaurant_city +
              ")"
            : "",
        no_of_child: data_call.no_of_child,
        no_of_guest: data_call.no_of_guest,
        counter_invoice: data_call.counter_invoice,
        no_of_pets: data_call.no_of_pets,
        book_time: data_call.book_time,
        book_date: data_call.book_date,
        booking_status: "1",
        guest_name: data_call.guest_name,
        show_msg: "Do you want to Accept the request?",
        button_name: "Accept",
        edit_click: true,
        button_name_next: "Cancel",
        button_class: "assignModalBtn",
        comfirm_booking: false,
      });
      setModalShow(true);
    } else if (click_type === "decline") {
      setSelectedData({
        primary_id: data_call.primary_id,
        restaurant_id: data_call.restaurant_id,
        restaurant_name:
          data_call.restaurant_list && data_call.restaurant_list.length > 0
            ? data_call.restaurant_list[0].restaurant_name +
              "(" +
              data_call.restaurant_list[0].restaurant_city +
              ")"
            : "",
        no_of_child: data_call.no_of_child,
        no_of_guest: data_call.no_of_guest,
        counter_invoice: data_call.counter_invoice,
        no_of_pets: data_call.no_of_pets,
        book_time: data_call.book_time,
        book_date: data_call.book_date,
        booking_status: "5",
        guest_name: data_call.guest_name,
        show_msg: "Do you want to Decline the request?",
        button_name: "Decline",
        edit_click: false,
        button_name_next: "Cancel",
        button_class: "",
        comfirm_booking: false,
      });
      setModalShow(true);
    } else if (click_type === "msgshow") {
      master_data_get_ajax(
        data_call.guest_mobile_no,
        data_call.primary_id,
        data_call.restaurant_id,

        1,
        data_call
      );
    } else if (click_type === "got_to_dashboard") {
      handleLinkClick("Dashboard");
    }
  };
  const handleActiveDeactive = () => {
    master_data_action_update(
      SelectedData.primary_id,
      SelectedData.booking_status,
      SelectedData.restaurant_id
    );
  };
  const master_data_get_ajax = async (
    guest_mobile_no,
    reservation_id,
    restaurant_id,
    flag,
    data_call
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("guest_mobile_no", guest_mobile_no);
    fd.append("reservation_id", reservation_id);
    fd.append("default_restaurant_id", restaurant_id);
    fd.append("flag", flag);
    await server_post_data(get_all_notes_by_id, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSelectedData(data_call);
          setSelectedDataDetails(Response.data.message.guest_data_data);
          setsStaffImageLinkData(
            APL_LINK + Response.data.message.data_link_image
          );
          setNoteShow2(true);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const [selectedValue, setSelectedValue] = useState("Name"); // State to hold the selected value

  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
    searchData.current.value = "";
  };

  const search_data = () => {
    flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  useEffect(() => {
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    setflagData(flag);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    fd.append("search_data", document.getElementById("search_data").value);
    await server_post_data(view_reservation_history, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditStaffData(Response.data.message.data_reservationInformation);
          setfilteredData(Response.data.message.data_reservationInformation);
          setMoneyiconright(Response.data.message.data_money_right);
          setMoneyiconleft(Response.data.message.data_money_left);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (
    reservation_id,
    booking_status,
    restaurant_id
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("reservation_id", reservation_id);
    fd.append("booking_status", booking_status);
    fd.append("default_restaurant_id", restaurant_id);
    fd.append("select_option_str", select_option_str);
    await server_post_data(update_action_reservation, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // toggleRef.current.click();
          handleClose();
          master_data_get(startDate, endDate, flagData, "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  return (
    <>
      <div className="reservationHistory">
        {showLoaderAdmin && <Loader />}
        <Header />
        <div className="pageCntn_head">
          <div className="pageNameDiv">
            <p>{GuestManagementPageText.Reservation_Management}</p>
            <img src={GreyArrow} alt="Dfoodo Dashboard" />
          </div>
        </div>
        <div className="viewStaff_head">
          <div className="row m-0 pb-2">
            <div className="col-lg-2 col-sm-4 bottomAlgin">
              <label className="labelView">
                {ViewStaffPagesText.Start_Date}
              </label>
              <div className="person__calenderFrame_image image_icon_position1 ">
                <input
                  id="startDate"
                  type="date"
                  placeholder={ViewStaffPagesText.D_O_B}
                  className="form-control  input_field_custom4"
                  defaultValue={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  max={computeTodayDate()}
                />
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 bottomAlgin">
              <label className="labelView">{ViewStaffPagesText.End_Date}</label>
              <div className="person__calenderFrame_image image_icon_position1 ">
                <input
                  id="endDate"
                  type="date"
                  placeholder={ViewStaffPagesText.D_O_B}
                  className="form-control  input_field_custom4"
                  defaultValue={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  max={computeTodayDate()}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 bottomAlgin">
              <div className="inputDiv2">
                <label className="labelPointer" htmlFor="selectFilter">
                  <img src={FilterIcon} alt="Dfoodo Dashboard" />
                </label>
                <select id="selectFilter" onChange={handleSelect}>
                  {options_search.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-5 col-sm-8 bottomAlgin">
              <div className="inputDiv2">
                <img src={Search} alt="Dfoodo Dashboard" />
                <input
                  type="text"
                  id="search_data"
                  ref={searchData}
                  onInput={(e) => {
                    if (selectedValue === options_search[0].value) {
                      handleAphabetsChange(e);
                    } else if (selectedValue === options_search[1].value) {
                      handleEmailChange(e);
                    } else if (selectedValue === options_search[2].value) {
                      handleNumbersChange(e);
                    }
                  }}
                  placeholder={ViewStaffPagesText.Search_Employee_reservation}
                />
                <button
                  type="button"
                  className="btnSearch"
                  onClick={() => search_data()}
                >
                  {ViewStaffPagesText.Search_text}
                </button>
              </div>
            </div>

            <div
              className="col-md-4 centerAlgin"
              style={{ marginLeft: "auto", marginRight: "0" }}
            >
              <div className="inputDiv2">
                <label className="labelPointer" htmlFor="selectFilter">
                  <img src={FilterIcon} alt="Dfoodo Dashboard" />
                </label>
                <input
                  type="text"
                  placeholder={ViewStaffPagesText.Staff_Filter_reservation}
                  onInput={handleIaphabetnumberChange}
                  value={searchfilter}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="reservationHistoryTable">
          <div className="historyTableContainer">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Time</th>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">
                    <img src={Person} alt="Dfoodo Dashboard" />
                  </th>
                  <th scope="col">Table</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((option, index) => (
                  <tr className="historyTableRow" key={index}>
                    <td>
                      <div
                        style={{
                          marginLeft: "1rem",
                          fontSize: "14px",
                          marginTop: "1.2rem",
                        }}
                      >
                        {index + 1}
                      </div>
                    </td>
                    <td>
                      <div className="topMargin historyTime">
                        <p className="colorred">
                          {option.restaurant_list &&
                            option.restaurant_list.length > 0 &&
                            option.restaurant_list[0].restaurant_name +
                              "(" +
                              option.restaurant_list[0].restaurant_city +
                              ")"}
                        </p>
                        <p>
                          {option.booking_type === 0 && <>Walk In : </>}
                          {option.booking_type === 1 && <>Reservation : </>}
                          {option.booking_type === 2 && <>Online : </>}

                          {formatDateStringdot(option.book_date)}
                        </p>
                        <div className="historyTimeImgText">
                          <img src={TimeTable} alt="Dfoodo Dashboard" />
                          <p>{formatTimeintotwodigit(option.book_time)}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="historyTableRowText historyName">
                        <h6 className="infoDetalisStats">
                          {option.guest_name}{" "}
                          {option.guest_status === 1 && (
                            <p className="statusName personTag">VIP</p>
                          )}
                          {option.customer_new_old === 0 && (
                            <p className=" statusName personTag">NEW</p>
                          )}
                        </h6>
                        <p>Contact No.: {option.guest_mobile_no}</p>
                        <p>{option.guest_email}</p>
                      </div>
                    </td>
                    <td>
                      <div className="historyTableRowText historyStatus">
                        {option.booking_status === 0 && (
                          <p className="toConfirm">To Confirm</p>
                        )}
                        {option.booking_status === 1 && (
                          <p className="toArrive">To Arrive</p>
                        )}
                        {option.booking_status === 2 && (
                          <p className="toreserved">Reserved</p>
                        )}

                        {option.booking_status === 3 && (
                          <p className="toCompleted">Completed</p>
                        )}

                        {option.booking_status === 4 && (
                          <p className="tonoshow">No Show</p>
                        )}

                        <p>ID : {option.counter_invoice}</p>
                        <p>
                          Booking date: {formatDateStringdot(option.entry_date)}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="historyTableRowText historyPerson">
                        <div>
                          <img src={PersonCount} alt="Dfoodo Dashboard" />
                          <p>{option.no_of_guest}P</p>
                        </div>
                        <div>
                          <img src={Pets} alt="Dfoodo Dashboard" />
                          <p>
                            {option.no_of_pets} pet
                            {option.no_of_pets > 1 && "s"}
                          </p>
                        </div>
                        <div>
                          <img src={Childs} alt="Dfoodo Dashboard" />
                          <p>
                            {option.no_of_child}{" "}
                            {option.no_of_child > 1 ? "children" : "child"}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="historyTableRowText historyActions">
                        <div className="historyActionsBtns mb-2">
                          {option.note_intruction != 0 && (
                            <button
                              className="instructions Add_Instructions"
                              onClick={() => handleShow(option, "msgshow")}
                            >
                              INSTRUCTIONS
                            </button>
                          )}

                          <button className="tableNo Add_Instructions">
                            {option !== null &&
                            option.table_code_names !== undefined &&
                            option.table_code_names !== "" &&
                            option.table_code_names !== null
                              ? option.table_code_names.replace(/,/g, "+")
                              : ""}
                          </button>
                        </div>
                        <div className="historyActionsBtns">
                          {option.booking_status === 0 && (
                            <>
                              <button
                                className="acceptResrv Accept_Reservation"
                                onClick={(e) => handleShow(option, "accepts")}
                              >
                                Accept
                              </button>
                              <button
                                className="noShowActiveResrv"
                                onClick={(e) => handleShow(option, "decline")}
                              >
                                Decline
                              </button>
                            </>
                          )}

                          {option.booking_status === 3 && (
                            <>
                              <div className="historyActionsBtns">
                                <button
                                  style={{ cursor: "default" }}
                                  className="billResrv"
                                >
                                  Bill No. {option.invoice_no_bill}
                                </button>
                                <button
                                  style={{ cursor: "default" }}
                                  className="declineResrv"
                                >
                                  {Moneyiconleft}
                                  {option.payout_amt}
                                  {Moneyiconright}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                {filteredData.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text_align_center">
                      No Results Found
                    </td>{" "}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          show={modalShow}
          className="releaseModal"
          centered
          onHide={(e) => handleClose(0)}
        >
          <Modal.Header className="releaseModalHeader"></Modal.Header>
          <Modal.Body className="releaseModalBody">
            <p> ID:{SelectedData.counter_invoice}</p>
            <div className="releaseModalHead">
              <div className="releaseModalHeadLeft align-items-start">
                <img
                  style={{ paddingTop: "0.2rem" }}
                  src={DoneGreen}
                  alt="Dfoodo Dashboard"
                />
                <h5>
                  <span>&nbsp;{SelectedData.show_msg}</span>
                </h5>
              </div>
            </div>
            <div className="releaseModalDetails">
              <h5> {SelectedData.restaurant_name}</h5>
              <h5>{SelectedData.guest_name}</h5>
              <p className="redtext">{SelectedData.table_code_names}</p>
              <p>
                BOOKING DATE : {inputdateformateChange(SelectedData.book_date)}
              </p>
              <p>
                BOOKING TIME : {formatTimeintotwodigit(SelectedData.book_time)}
              </p>
              <div className="countOfGuests">
                <p>
                  {SelectedData.no_of_guest}{" "}
                  {SelectedData.no_of_guest > 1 ? "PERSON" : "PERSON"}{" "}
                </p>
                <p>
                  {SelectedData.no_of_child}{" "}
                  {SelectedData.no_of_child > 1 ? "CHILDREN" : "CHILD"}
                </p>
                <p>
                  {SelectedData.no_of_pets}{" "}
                  {SelectedData.no_of_pets > 1 ? "PETS" : "PET"}{" "}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="releaseModalFooter">
            <Button
              className={`releaseModalBtn ${SelectedData.button_class}`}
              onClick={handleActiveDeactive}
            >
              {SelectedData.button_name}
            </Button>

            <Button
              className="editNowBtn Edit_Now"
              onClick={(e) => handleClose(1)}
            >
              <label style={{ cursor: "pointer" }}>
                {SelectedData.button_name_next}
              </label>
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={noteShow2}
          className="releaseModal"
          centered
          onHide={handleNoteClose2}
        >
          <Modal.Header
            style={{ border: "none", paddingBottom: "0" }}
            closeButton
          ></Modal.Header>
          <Modal.Body className="releaseModalBody notesDoby">
            <div className="booking-container">
              <>
                <div className="booking-details">
                  <div className="booking-details-label">
                    <label className="table-number">
                      {SelectedData.restaurant_list &&
                      SelectedData.restaurant_list.length > 0
                        ? SelectedData.restaurant_list[0].restaurant_name +
                          "(" +
                          SelectedData.restaurant_list[0].restaurant_city +
                          ")"
                        : ""}
                    </label>{" "}
                    <label>Booking Details</label>{" "}
                    <p> ID:{SelectedData.counter_invoice}</p>
                  </div>
                  <div className="booking-info">
                    <div className="booking-date">
                      {inputdateformateChange(SelectedData.book_date)}
                    </div>
                    <div className="booking-time">
                      Booking Time :{" "}
                      {formatTimeintotwodigit(SelectedData.book_time)}
                    </div>
                    <div className="table-number">
                      {SelectedData !== null &&
                      SelectedData.table_code_names !== undefined &&
                      SelectedData.table_code_names !== "" &&
                      SelectedData.table_code_names !== null
                        ? SelectedData.table_code_names.replace(/,/g, "+")
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="guest-details">
                  <div className="guest-name-wrapper">
                    <div className="guest-name">{SelectedData.guest_name}</div>

                    <div className="guest-count">
                      <p>{SelectedData.no_of_guest} PERSON</p>

                      <p>
                        {SelectedData.no_of_child}{" "}
                        {SelectedData.no_of_child > 1 ? "CHILDREN" : "CHILD"}
                      </p>
                      <p>
                        {SelectedData.no_of_pets}{" "}
                        {SelectedData.no_of_pets > 1 ? "PETS" : "PET"}{" "}
                      </p>
                    </div>
                    <div className="guest-name">
                      {SelectedData.reservation_description}
                    </div>
                  </div>
                </div>
                {SelectedDataDetails && SelectedDataDetails.length > 0 && (
                  <div className="additional-info addtional_notes_css">
                    <div className="info-header">
                      <div className="info_header_container">
                        <div>
                          <img
                            src={OrngMsg}
                            alt="Calendar icon"
                            className="calendar-icon"
                          />
                        </div>

                        <div className="w-100">
                          {SelectedDataDetails &&
                            SelectedDataDetails.map((note, indexsss) => (
                              <>
                                <div
                                  key={indexsss}
                                  className="info-header-left"
                                >
                                  <div className="staff-info">
                                    <img
                                      src={
                                        StaffImageLinkData + note.staff_image
                                      }
                                      onError={(e) => (e.target.src = userIcn)}
                                      alt="Staff avatar"
                                      className="staff-avatar"
                                    />
                                    <div className="staff-name">
                                      {note.staff_name}
                                    </div>
                                  </div>
                                  <div className="booking-datetime">
                                    {inputdateformateChange(note.entry_date)}{" "}
                                    <span
                                      style={{
                                        color: "rgba(245, 134, 52, 1)",
                                      }}
                                    >
                                      I
                                    </span>{" "}
                                    {formatTimeFormatcustom(note.entry_date)}{" "}
                                  </div>
                                </div>
                                <div className="info-header-right">
                                  <div className="note-item">
                                    {note.note_details}
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ReservationHistory;
