import React, { useState, useEffect, useRef } from "react";
import Edit from "../assets/edit_square.png";
import { Pagination } from "react-bootstrap";
import Delete from "../assets/delete.svg";
import Loader from "./Loader.js";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleSuccess,
  formatDateStringdot,
  handleConfimDeleteClick,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  get_all_faq_website,
  save_update_faq_website,
} from "../ServiceConnection/serviceconnection.js";
import {
  BlogWebsite_text,
  add_faqs_text,
} from "../CommonJquery/WebsiteText.js";

const FaqWebsite = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [editStaffDataDetails, seteditStaffDataDetails] = useState([]);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [formVisible, setFormVisible] = useState(false);
  const handleToggleForm = () => {
    seteditStaffDataDetails({});
    setEditorDatMainID(0);
    setFormVisible(!formVisible);
  };
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  const handleEdit = (data_call) => {
    master_data_get("", "", "3", data_call.primary_id);
  };

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get("", "", flag, call_id, "");
  }, []);

  const tableRef = useRef(null);

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    select_type
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("select_type", select_type);
    await server_post_data(get_all_faq_website, fd)
      .then((Response) => {
        
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          
          if (flag === "3") {
            seteditStaffDataDetails(Response.data.message.data[0]);
            setEditorDatMainID(Response.data.message.data[0].primary_id);
            setFormVisible(true);
            console.log(flag);
          } else {
            seteditStaffData(Response.data.message.data);
            setEditorDatMainID(0);
            setFormVisible(false);
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const indexOfLastItem = currentPage * itemsPerPage;

  const totalPageCount = Math.ceil(editStaffData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };

  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("main_id", call_id);
      fd.append("flag", "3");
      await server_post_data(save_update_faq_website, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("main_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccess(Response.data.message);

            setFormVisible(false);
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div>
      {showLoaderAdmin && <Loader />}
      {!formVisible && (
        <>
          <div className="headSeo justify-content-end">
            <button
              type="button"
              onClick={handleToggleForm}
              className="btn btn-secondary"
            >
              Add FAQ
            </button>
          </div>
          <div className="feedBackTable_container mt-3">
            <table id="myTable" className="display table" ref={tableRef}>
              <thead>
                <tr>
                  <th scope="col" className="th3">
                    S. No.
                  </th>
                  <th scope="col">Entry Date</th>
                  <th scope="col">Question</th>
                  <th scope="col">Answer</th>
                  <th className="th4">Action</th>
                </tr>
                <tr style={{ height: "25px" }}></tr>
              </thead>
              <tbody>
                {editStaffData.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="th3">
                        <div className="recentANme">
                          <p>{index + 1}</p>
                        </div>
                      </td>
                      <td>
                        <div className="recentContact">
                          <p>{formatDateStringdot(item.entry_date)}</p>
                        </div>
                      </td>

                      <td>
                        <div className="recentContact">
                          <p>
                            {item.question_name.slice(0, 20)}
                            {item.question_name.length > 20 ? `...` : ``}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="recentContact">
                          <p>
                            {item.answer_name.slice(0, 20)}
                            {item.answer_name.length > 20 ? `...` : ``}
                          </p>
                        </div>
                      </td>
                      <td className="th4">
                        <div className="recentReservBtns">
                          <button
                            className="mb-2"
                            style={{ width: "90px" }}
                            onClick={() => handleEdit(item)}
                          >
                            <p>Edit</p>
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                              src={Edit}
                              alt="Dfoodo Dashboard"
                            />
                          </button>
                          <button
                            className="mb-2"
                            style={{ width: "90px" }}
                            onClick={() => master_data_action(item.primary_id)}
                          >
                            <p>Delete</p>
                            <img src={Delete} alt="Dfoodo Dashboard" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr style={{ height: "1rem", boxShadow: "none" }}></tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <Pagination>
              <div className="paginationContainer">
                <div className="nxtBttnTble">
                  {currentPage !== 1 ? (
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
                      }
                    >
                      Previous
                    </button>
                  ) : null}
                </div>
                <div className="d-flex gap-2">{renderPaginationItems()}</div>
              </div>
            </Pagination>
          </div>
        </>
      )}

      {formVisible && (
        <form id="addNewStaff">
          <div className="event-box blog-data">
            <button
              style={{ fontWeight: "500" }}
              type="button"
              className="btn custom-btn"
            >
              FAQ
            </button>

            <div className="form-group mt-4">
              <div className="row m-0">
                <div className={`col-md-8`}>
                  <div className="inpContainer">
                    <label className="no_prsnl_id">
                      {add_faqs_text.Question}
                      <span>*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        id="question"
                        name="question_name"
                        className={`  trio_mandatory  input_field_customPadding form-control`}
                        placeholder={add_faqs_text.Enter_question}
                        defaultValue={editStaffDataDetails.question_name || ""}
                      />

                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>

                <div className={`col-md-12`}>
                  <div className="inpContainer">
                    <label className="no_prsnl_id">
                      {add_faqs_text.Answer}
                      <span>*</span>
                    </label>
                    <div>
                      <textarea
                        type="text"
                        id="question"
                        name="answer_name_dd"
                        className={`  trio_mandatory  input_field_customPadding form-control`}
                        placeholder={add_faqs_text.Enter_answer}
                        defaultValue={editStaffDataDetails.answer_name || ""}
                      />

                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="addBlogBtnDiv">
                    <button
                      type="button"
                      onClick={() =>
                        handleSaveChangesdynamic(
                          "addNewStaff",
                          save_update_faq_website
                        )
                      }
                      className="btn btn-secondary mt-3 save-cap-btn"
                    >
                      {BlogWebsite_text.save_txt}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
export default FaqWebsite;
