import React, { useState } from "react";
import Header from "./Header";
import WebRestroInfo from "./WebRestroInfo";
import AddImage from "./AddImage";
import SeoWebsite from "./SeoWebsite";
import BlogWebsite from "./BlogWebsite";
import EmailSubs from "./EmailSubsView";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import FaqWebsite from "./FaqWebsite";

function WebsiteMngt() {
  const [restriInfo, setrestriInfo] = useState(true);
  const [addImage, setaddImage] = useState(false);
  const [blog, setblog] = useState(false);
  const [Faq, setfaq] = useState(false);
  const [seo, setseo] = useState(false);
  const [emailSubs, setemailSubs] = useState(false);
  const [terms, setterms] = useState(false);
  const [privacy, setprivacy] = useState(false);

  const toggleSettingsBar = (TabId) => {
    setrestriInfo(TabId === "1");
    setaddImage(TabId === "2");
    setblog(TabId === "3");
    setseo(TabId === "4");
    setemailSubs(TabId === "6");
    setprivacy(TabId === "11");
    setterms(TabId === "10");
    setfaq(TabId === "12");
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="settingsTabs">
                <div className="settingsTabs_container">
                  <ul>
                    <li
                      className={`${restriInfo ? "currentTab" : ""}`}
                      onClick={() => toggleSettingsBar("1")}
                    >
                      <div className="setTab setTab1">
                        <p>Website Info</p>
                      </div>
                    </li>
                    <li className={`${addImage ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("2")}
                      >
                        <p>Add Image</p>
                      </div>
                    </li>
                    <li className={`${blog ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("3")}
                      >
                        <p>Blog</p>
                      </div>
                    </li>
                    <li className={`${Faq ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("12")}
                      >
                        <p>FAQ</p>
                      </div>
                    </li>
                    <li className={`${seo ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("4")}
                      >
                        <p>SEO</p>
                      </div>
                    </li>

                    <li className={`${emailSubs ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("6")}
                      >
                        <p>Email Subs.</p>
                      </div>
                    </li>
                    <li className={`${terms ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("10")}
                      >
                        <p>Terms & Condition</p>
                      </div>
                    </li>
                    <li className={`${privacy ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("11")}
                      >
                        <p>Privacy Policy</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="settingstabsContainer">
                {restriInfo && <WebRestroInfo />}
                {addImage && <AddImage />}
                {seo && <SeoWebsite />}
                {blog && <BlogWebsite />}
                {Faq && <FaqWebsite />}
                {emailSubs && <EmailSubs />}
                {terms && <TermsOfUse />}
                {privacy && <PrivacyPolicy />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteMngt;
