
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import EmployeeImg from "../assets/storeNew1.png";
import NORestro from "../assets/norestro.png";
import { Link } from "react-router-dom";
import Editred from "../assets/editred.svg";
import DltBtn from "../assets/delete.svg";
import DeactiIcon from "../assets/deactiIcon.png";
import { Modal, Button } from "react-bootstrap";
import {
  server_post_data,
  delete_super_master_data,
  active_status_super_master,
  get_all_admin_Country,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleLinkClick,
  handleSuccess,
  handleURLChange,
} from "../CommonJquery/CommonJquery";
import {
  Open_Menu_text,
  ViewStaffRightPages,
  ViewStaffPagesText,
} from "./../CommonJquery/WebsiteText";

function CountryView() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [noRestroAdded, setNoRestroAdded] = useState("1");
  const [editStaffData, seteditStaffData] = useState([]);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [SelectedData, setSelectedData] = useState([]);
  const [SelectedData1, setSelectedData1] = useState([]);
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_admin_Country, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditStaffData(Response.data.message.data_blog);
          // setNoRestroAdded(Response.data.message.show_create_option);

          // setsStaffImageLinkData(
          //   APL_LINK + Response.data.message.data_restaurant_image
          // );
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    console.log(call_id);
    fd.append("id_for_delete", call_id);
    fd.append("flag_for", "2");
    fd.append("for_status_final", for_status_final);
    await server_post_data(delete_super_master_data, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose1();
          handleLinkClick(" ");
          // master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update_data = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    console.log(call_id);
    console.log(for_status_final);
    fd.append("id_for_active", call_id);
    fd.append("flag_for", "2");
    fd.append("for_status_final", for_status_final);
    await server_post_data(active_status_super_master, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          handleLinkClick(" ");
          // master_data_get("", "", "1", "0");
          handleClose();
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleClose = () => setModalShow(false);
  const handleClose1 = () => setModalShow1(false);
  const handleShow = (data_call) => {
    setSelectedData(data_call);
    console.log(data_call);
    setModalShow(true);
  };
  const handleShow1 = (data_call) => {
    setSelectedData(data_call);
    console.log(data_call);
    setModalShow1(true);
  };
  const handleDeactivate = () => {
    handleActiveDeactive();
  };

  const handleActiveDeactive = () => {
    let allow_access_data = "";
    console.log(SelectedData.active_status);
    if (SelectedData.active_status == "1") {
      console.log("test");
      allow_access_data = "0";
    } else {
      allow_access_data = "1";
    }
    // console.log(SelectedData.id, SelectedData.flag);
    master_data_action_update_data(SelectedData.primary_id, allow_access_data);

    {
      console.log(SelectedData.primary_id);
    }
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageCntn_head_left">
                {/* <div className="pageNameDiv">
                  <p>{FloorPlanMngmntPage.Floor_Management} </p>
                  <img src={GreyArrow} alt="Dfoodo Dashboard" />
                </div> */}
              </div>
            </div>

            <div className="page_body height80 container-lg">
              
              {noRestroAdded === "1" && (
                <div className="view_restroCOntainer">
                  
                  <div className="restroView_table">
                    <div className="restroView_table_Container">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Country Name</th>
                            <th scope="col">Total City</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {editStaffData.map((option, index) => (
                            <>
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="th1">
                                    <div className="rsvnDetails saparator1">
                                     
                                      <div className="rsvnDetailsText">
                                        <h6>{option.country}</h6>
                                       
                                      </div>
                                    </div>
                                  </td>
                                
                                  <td className="th2 flex align-middle">
                                  <div className="rsvnDetailsText ">
                                    <h6 className="">{option.total_city}</h6>
                                  </div>
                                  </td>
                                </tr>
                                <tr
                                  //   key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default CountryView;
