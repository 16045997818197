import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import Person from "../assets/storefrontGrey.svg";
import editImg from "../assets/edit_square.png";
import Select from "react-select";
import {
  AddStaffPageText,
  create_restaurants,
} from "./../CommonJquery/WebsiteText";
import {
  handleEmailChange,
  handleNumbersChange,
  handleAphabetsChange,
  handleError,
  handlePasswordChange,
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  empty_form,
  handleIaphabetnumberChange,
  cencelChanges,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_landmark,
  get_landmark_data,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";
function LandMarkAdd() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [designations, setdesignations] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [category, setCategory] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [permanentAddress, setPermanentAddress] = useState("");
  const [temporaryAddress, setTemporaryAddress] = useState("");
  const [copyAddress, setCopyAddress] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);
  const [amenitiesSelected, setAmenitiesSelected] = useState([]);
  const [cuisineSelected, setCuisineSelected] = useState([]);

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      // if (file.size < 200 * 1024) {
      //   // 200KB in bytes
      //   alert("File size is below the minimum limit (200KB).");
      //   return;
      // }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        alert("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    console.log(vaild_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);

      fd_from.append("main_id", editorDataMainID);
      console.log(url_for_save);
      fd_from.append("old_image_link", editOldImageData);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/View_landmark");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    try {
      const input = document.getElementById("searchInput");
      const autocomplete = new window.google.maps.places.Autocomplete(input);

      autocomplete.addListener("place_changed", function () {
        const place = autocomplete.getPlace();
        let full_address = place.address_components;
        let formatted_address = place.formatted_address;
        let length_data = place.address_components.length;
        let citys = "";
        let state = "";
        let country = "";
        let tehsil = "";
        for (let i = 0; i < length_data; i++) {
          if (full_address[i].types[0] === "administrative_area_level_1") {
            state = full_address[i].long_name;
          } else if (full_address[i].types[0] === "country") {
            country = full_address[i].long_name;
          } else if (full_address[i].types[0] === "administrative_area_level_2") {
            citys = full_address[i].long_name;
          } else if (full_address[i].types[0] === "locality") {
            tehsil = full_address[i].long_name;
          }
        }
        if (tehsil !== "") {
          citys = tehsil;
        }
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setPermanentAddress(formatted_address);
        document.getElementById("restaurant_city").value = citys;
        document.getElementById("restaurant_state").value = state;
        document.getElementById("restaurant_country").value = country;
        document.getElementById("lng").value = longitude;
        document.getElementById("lat").value = latitude;
      });
    } catch (error) {
      console.log(error)
    }
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_landmark_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_reviews.length > 0) {
            seteditStaffData(Response.data.message.data_reviews[0]);
            setEditorDatMainID(
              Response.data.message.data_reviews[0].primary_id
            );
            seteditOldImageData(
              Response.data.message.data_reviews[0].image_name
            );
            setPermanentAddress(
              Response.data.message.data_reviews[0].landmark_map_name
            );
            setDynaicimage({
              event_list_image_show:
                APL_LINK +
                Response.data.message.data_link_image +
                Response.data.message.data_reviews[0].image_name,
            });
            setTemporaryAddress(
              Response.data.message.data_reviews[0].landmark_name
            );
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleCheckboxChange2 = () => {
    setCopyAddress(!copyAddress); // Toggle checkbox state
    if (!copyAddress) {
      setTemporaryAddress(permanentAddress); // Copy permanent address to temporary address
    } else {
      setTemporaryAddress(""); // Clear temporary address
    }
  };

  const handleChange1 = (selectedOption) => {
    setCategorySelected(selectedOption);
  };
  const handleChange2 = (selectedOption) => {
    setAmenitiesSelected(selectedOption);
  };
  const handleChange3 = (selectedOption) => {
    setCuisineSelected(selectedOption);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>Area Details</p>
                {/* <p>{create_restaurants.Restaurant_details}</p> */}
                <img src={GreyArrow} alt="Dfoodo Dashboard" />
              </div>
            </div>

            <div className="page_body">
              <div className="addStaffForms">
                <form id="addNewStaff">
                  <div className="col-xl-7 col-lg-7 col-md-10 m-auto">
                    <div className="personalForm">
                      <div className="staffForm_container">
                        <div className="row m-0">
                          <div className="col-lg-3 col-md-4 mx-auto">
                            <div
                              className={
                                "inpContainer imginputContainer flex-column "
                              }
                            >
                              <div
                                className={
                                  dynaicimage ? "" : " inputDiv1 imgInput"
                                }
                              >
                                <label
                                  htmlFor="event_list_image"
                                  className="imgInptImg"
                                >
                                  {dynaicimage &&
                                    dynaicimage.event_list_image_show ? (
                                    <div
                                      className="imgDiv"
                                      htmlFor="event_list_image"
                                    >
                                      <img
                                        src={dynaicimage.event_list_image_show}
                                        onError={(e) => (e.target.src = Person)}
                                        alt="Preview"
                                      />
                                      <img
                                        className="profileImgEdit"
                                        src={editImg}
                                        alt="Dfoodo Dashboard"
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <img
                                        style={{ width: "1.5rem" }}
                                        src={Person}
                                        alt="Dfoodo Dashboard"
                                      />
                                      <p className="m-0">
                                        {AddStaffPageText.Add_Image}
                                      </p>
                                      <div className="imgValidationalert ">
                                        {/* <p>
                                          {AddStaffPageText.Image_Validation}
                                        </p> */}
                                        <p>
                                          {AddStaffPageText.Image_Validation2}
                                        </p>
                                      </div>
                                    </>
                                  )}
                                </label>
                              </div>
                              <input
                                name="event_list_image"
                                id="event_list_image"
                                type="file"
                                onChange={handleFileChangedynamic(
                                  "event_list_image"
                                )}
                                className="  form-control"
                                accept=".jpg,.jpeg,.png"
                                hidden
                              />
                              {/* <span className="condition_error"></span> */}
                            </div>
                          </div>

                          {/* <div className="row m-0"> */}
                          <div className="col-md-12">
                            <div className="inpContainer">
                              <div className="image_icon_position1">
                                <label className="no_prsnl_id">
                                  Area Front Name
                                </label>
                                <textarea
                                  type="text"
                                  id=""
                                  rows="4"
                                  name="restaurant_temorary_adrress"
                                  maxLength={250}
                                  minLength={2}
                                  value={temporaryAddress}
                                  placeholder="Enter Area Front Name"
                                  className="trio_mandatory form-control input_field_custom3"
                                  onChange={(e) =>
                                    setTemporaryAddress(e.target.value)
                                  }
                                  // className="form-control input_field_custom3 "
                                  defaultValue={
                                    editStaffData.landmark_name || ""
                                  }
                                ></textarea>
                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="inpContainer ">
                              <div className="image_icon_position1">
                                <label className="no_prsnl_id">
                                  Area Google Address
                                </label>
                                <textarea
                                  type="text"
                                  rows="7"
                                  id="searchInput"
                                  name="restaurant_full_adrress"
                                  maxLength={250}
                                  minLength={2}
                                  placeholder="Area Google Address"
                                  value={permanentAddress}
                                  onChange={(e) =>
                                    setPermanentAddress(e.target.value)
                                  }
                                  className="trio_mandatory form-control input_field_custom3"
                                  defaultValue={
                                    editStaffData.landmark_map_name || ""
                                  }
                                ></textarea>
                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div className="form-row ">
                            <div className="col-md-12 mb-3">
                              <label htmlFor="validationCustom01">City</label>
                              <input
                                type="text"
                                className="form-control  searchInput_google"
                                name="restaurant_city"
                                id="restaurant_city"
                                maxLength={100}
                                minLength={2}
                                // onInput={handleAphabetsChange}
                                placeholder={AddStaffPageText.City_Name}
                                defaultValue={editStaffData.city || ""}
                              />
                              <span className="condition_error"></span>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label htmlFor="validationCustom01">
                                {AddStaffPageText.State}
                              </label>
                              <input
                                type="text"
                                className="form-control  "
                                name="restaurant_state"
                                id="restaurant_state"
                                maxLength={100}
                                // onInput={handleAphabetsChange}
                                placeholder={AddStaffPageText.Enter_State}
                                defaultValue={editStaffData.state || ""}
                              />
                              <span className="condition_error"></span>
                            </div>
                            <div className="col-md-12 mb-3 ">
                              <label htmlFor="validationCustom01">
                                {AddStaffPageText.Country}
                              </label>
                              <input
                                type="text"
                                className="form-control  "
                                name="restaurant_country"
                                id="restaurant_country"
                                maxLength={100}
                                // onInput={handleAphabetsChange}
                                placeholder={AddStaffPageText.Enter_Country}
                                defaultValue={editStaffData.country || ""}
                              />
                              <span className="condition_error"></span>
                            </div>
                            <div className="col-md-4 mb-3 hidden">
                              <label htmlFor="validationCustom01">
                                {AddStaffPageText.Country}
                              </label>
                              <input
                                type="text"
                                className="form-control  "
                                name="lng"
                                id="lng"
                                maxLength={100}
                                // onInput={handleAphabetsChange}
                                placeholder={AddStaffPageText.lng}
                                defaultValue={editStaffData.lng || ""}
                              />
                              <span className="condition_error"></span>
                            </div>
                            <div className="col-md-4 mb-3 hidden">
                              <label htmlFor="validationCustom01">
                                {AddStaffPageText.Country}
                              </label>
                              <input
                                type="text"
                                className="form-control  "
                                name="lat"
                                id="lat"
                                maxLength={100}
                                // onInput={handleAphabetsChange}
                                placeholder={AddStaffPageText.lat}
                                defaultValue={editStaffData.lat || ""}
                              />
                              <span className="condition_error"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="saveFormBtns">
                      <button
                        className="btnCancel"
                        type="button"
                        onClick={cencelChanges}
                      >
                        {AddStaffPageText.cencel_text}
                      </button>

                      <button
                        className="btnSave"
                        type="button"
                        onClick={() =>
                          handleSaveChangesdynamic(
                            "addNewStaff",
                            save_update_landmark
                          )
                        }
                      >
                        {location.pathname.includes("/edit_restaurants")
                          ? AddStaffPageText.update_text
                          : AddStaffPageText.save_text}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandMarkAdd;
