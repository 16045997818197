import React, { useState, useEffect } from "react";
import Loader from "./Loader.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  get_update_terms_privacy,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
const TermsOfUse = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [SelectedData, setSelectedData] = useState([]);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [editStaffDataDetails, seteditStaffDataDetails] = useState([]);
  const [editorData, setEditorData] = useState("");
  const [error_show, seterror_show] = useState("");

  useEffect(() => {
    master_data_get("0");
  }, []);

  const handleEditorChange = (event, editor) => {
    setEditorData(editor.getData());
  };

  const master_data_get = async (flag) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", flag);
    await server_post_data(get_update_terms_privacy, fd)
      .then((Response) => {
        console.log(Response.data.message[0]);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          //   console.log(Response.data.message[0].terms_condition);
          seteditStaffDataDetails(Response.data.message[0]);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    const fd_from = new FormData();
    fd_from.append("flag", 1);
    fd_from.append("data", editorData);
    await server_post_data(url_for_save, fd_from)
      .then((Response) => {
        console.log(Response.data.message);
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get("0");
          handleSuccess(Response.data.message);
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  return (
    <div>
      {showLoaderAdmin && <Loader />}
      <div className="row m-0">
        <div className={`col-md-12`}>
          <div className="inpContainer mt-2">
            <label className="no_prsnl_id">
              {/* {BlogWebsite_text.Description} */}
              Terms And Condition
              <span>*</span>
            </label>
            <div>
              <CKEditor
                editor={ClassicEditor}
                data={editStaffDataDetails.terms_condition || ""}
                onChange={handleEditorChange}
              />
            </div>
            <span className="condition_error">{error_show}</span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="addBlogBtnDiv">
            <button
              type="button"
              onClick={() =>
                handleSaveChangesdynamic(
                  "addNewStaff",
                  get_update_terms_privacy
                )
              }
              className="btn btn-secondary mt-3 save-cap-btn"
            >
              {/* {BlogWebsite_text.save_txt} */}
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsOfUse;
