import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import EmployeeImg from "../assets/storeNew1.png";
import NORestro from "../assets/norestro.png";
import { Link } from "react-router-dom";
import Editred from "../assets/editred.svg";
import DltBtn from "../assets/delete.svg";
import DeactiIcon from "../assets/deactiIcon.png";
import { Modal, Button } from "react-bootstrap";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  server_post_data,
  delete_super_master_data,
  delete_city_super,
  active_status_super_master,
  get_all_admin_City,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleLinkClick,
  handleSuccess,
  handleURLChange,
} from "../CommonJquery/CommonJquery";
import {
  Open_Menu_text,
  ViewStaffRightPages,
  ViewStaffPagesText,
} from "./../CommonJquery/WebsiteText";

function CityView() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [noRestroAdded, setNoRestroAdded] = useState("1");
  const [editStaffData, seteditStaffData] = useState([]);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [SelectedData, setSelectedData] = useState([]);
  const [SelectedData1, setSelectedData1] = useState([]);
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_admin_City, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditStaffData(Response.data.message.data_blog);
          // setNoRestroAdded(Response.data.message.show_create_option);

          // setsStaffImageLinkData(
          //   APL_LINK + Response.data.message.data_restaurant_image
          // );
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    console.log(call_id);
    fd.append("id_for_delete", call_id);
    // fd.append("flag_for", "4");
    // fd.append("for_status_final", for_status_final);
    await server_post_data(delete_city_super, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose1();
          handleLinkClick(" ");
          // master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update_data = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    console.log(call_id);
    console.log(for_status_final);
    fd.append("id_for_active", call_id);
    fd.append("flag_for", "4");
    fd.append("for_status_final", for_status_final);
    await server_post_data(active_status_super_master, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          handleLinkClick(" ");
          // master_data_get("", "", "1", "0");
          handleClose();
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleClose = () => setModalShow(false);
  const handleClose1 = () => setModalShow1(false);
  const handleShow = (data_call) => {
    setSelectedData(data_call);
    console.log(data_call);
    setModalShow(true);
  };
  const handleShow1 = (data_call) => {
    setSelectedData(data_call);
    console.log(data_call);
    setModalShow1(true);
  };
  const handleDeactivate = () => {
    handleActiveDeactive();
  };

  const handleActiveDeactive = () => {
    let allow_access_data = "";
    console.log(SelectedData.active_status);
    if (SelectedData.active_status == "1") {
      console.log("test");
      allow_access_data = "0";
    } else {
      allow_access_data = "1";
    }
    // console.log(SelectedData.id, SelectedData.flag);
    master_data_action_update_data(SelectedData.primary_id, allow_access_data);

    {
      console.log(SelectedData.primary_id);
    }
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />

            <div className="pageCntn_head">
              {/* <div className="pageCntn_head_left"> */}

              {/* </div> */}
            </div>

            <div className="page_body height80 container-lg">
              {noRestroAdded === "0" && (
                <div className="createRestro">

                  <div className="no_restroContainer">
                    <div className="no_restroImg">
                      <img src={NORestro} alt="Dfoodo Dashboard" />
                      <Link to="/city_add">
                        <button className="createRetroBtn Create_Restaurant">
                          Create City
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {noRestroAdded === "1" && (
                <div className="view_restroCOntainer">
                  <div className={"cityHeader"}>
                    <div className="pageNameDiv ">
                      <p>City Management</p>
                      <img src={GreyArrow} alt="Dfoodo Dashboard" />
                    </div>
                    <div  >
                      <Link to="/city_add">
                        <button
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="Add Restaurant"
                          className="createDiningArea createRestaurant Add_Restaurant"
                        >
                          Add City
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="restroView_table">
                    <div className="restroView_table_Container">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">City Name</th>
                            {/* <th scope="col">City Name</th> */}
                            <th scope="col">Country Name</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {editStaffData.map((option, index) => (
                            <>
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="th1">
                                    <div className="rsvnDetails saparator1">
                                      <div className="rsvnDetailsImg">
                                        <img
                                          src={
                                            APL_LINK +
                                            "/assets/" +
                                            option.image_name
                                          }
                                          onError={(e) =>
                                            (e.target.src = EmployeeImg)
                                          }
                                          alt="Dfoodo Dashboard"
                                        />
                                      </div>
                                      <div className="rsvnDetailsText">
                                        <h6>{option.city}</h6>
                                        {/* <p>{option.restaurant_tagline}</p> */}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="rsvnDetails">
                                      <div className="rsvnContactDetails">
                                        <h6>{option.country}</h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="guest_incenter tbodyStyle shadowOnlyBottom  mt-3">
                                      <div
                                        className="on-off-toggle"
                                        onClick={(e) => {
                                          handleShow(option);
                                        }}
                                      >
                                        <input
                                          className="on-off-toggle__input"
                                          type="checkbox"
                                          id={`dynamic_id${option.primary_id}`}
                                          defaultChecked={
                                            option.active_status == 1
                                              ? "checked"
                                              : ""
                                          }
                                        />

                                        <label
                                          // htmlFor={`dynamic_id${option.primary_id}`}
                                          className="on-off-toggle__slider"
                                        ></label>
                                      </div>
                                    </div>
                                  </td>

                                  <td className="th2">
                                    <div className="rsvnDetails">
                                      {/* <Link
                                        to={`/cuisines_edit/${option.primary_id}`}
                                      >
                                        <button className="editRestroBtn Edit_Restro ">
                                          <img
                                            src={Editred}
                                            alt="Dfoodo Dashboard"
                                          />
                                          <p>Edit</p>
                                        </button>
                                      </Link> */}
                                      {/* <Link
                                        to={`/edit_restaurants/${option.primary_id}`}
                                      > */}
                                      <button
                                        className="editRestroBtn Edit_Restro"
                                        onClick={() =>
                                          // master_data_action_update(
                                          //   option.primary_id,
                                          //   "d"
                                          // )
                                          handleShow1(option)
                                        }
                                      >
                                        <img
                                          src={DltBtn}
                                          alt="Dfoodo Dashboard"
                                        />
                                      </button>
                                      {/* </Link> */}
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  //   key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        className="confirmModal"
        centered
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          {/* <img src={DeactiIcon} alt="Dfoodo Dashboard" /> */}
          <p className="modalText">
            {ViewStaffPagesText.model_text}{" "}
            {SelectedData.active_status === 0 ? "Activate" : "Deactivate"}?
          </p>

          <p className="modalTextRed">Name: {SelectedData.city} </p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`${SelectedData.active_status === 0 ? "closeConfirmAeBtn" : ""
              } closeConfirmBtn`}
            onClick={handleClose}
          >
            {ViewStaffPagesText.Close_text}
          </Button>
          <Button
            className={`${SelectedData.active_status === 0 ? "confirmAeBtn" : "confirmDeBtn"
              } Confirm_Deactive`}
            onClick={handleDeactivate}
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              {SelectedData.active_status === 0 ? "Activate" : "Deactivate"}
            </label>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalShow1}
        className="confirmModal"
        centered
        onHide={handleClose1}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          {/* <img src={DeactiIcon} alt="Dfoodo Dashboard" /> */}
          <p className="modalText">
            {ViewStaffPagesText.model_text} Delete
            {/* {SelectedData.active_status === 0 ? "Activate" : "Deactivate"}? */}
          </p>

          <p className="modalTextRed">Name: {SelectedData.city} </p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`${SelectedData.active_status === 0 ? "closeConfirmAeBtn" : ""
              } closeConfirmBtn`}
            onClick={handleClose1}
          >
            {ViewStaffPagesText.Close_text}
          </Button>
          <Button
            // className={`${
            //   SelectedData.active_status === 0 ? "confirmAeBtn" : "confirmDeBtn"
            // } Confirm_Deactive`}
            className={` confirmDeBtn
             Confirm_Deactive`}
            // onClick={handleDeactivate}
            onClick={() =>
              master_data_action_update(SelectedData.primary_id, "d")
            }
          >
            <label
              style={{ cursor: "pointer" }}
            // htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Delete
              {/* {SelectedData.active_status === 0 ? "Activate" : "Deactivate"} */}
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CityView;
